const trademark = String.fromCodePoint(0x2122);

export const getSubTitle = (code) => {
  return `Please fill out the required fields below including your dependent’s email so that they can be invited to complete enrollment. You can add up to ${code} ${code > 1 ? "depenents" : "dependent"
    }.`;
};

// To give the Dymanic count for Dependednt count
export const getprovider = (dependentCount) => {
  return `Please fill out the required fields below including your dependent’s email so that they can be invited to complete enrollment. You can add up to ${dependentCount} ${dependentCount > 1 ? "depenents" : "dependent"
    }.`;
};

const textConstants = {
  enrollment: {
    title: {
      employee: "KardiaComplete Enrollment",
      provider: "Add Dependent",
      dependent: "KardiaComplete Enrollment",
    },
    subtitle: {
      employee: `Take control of your heart health with KardiaComplete.`,
      provider: `Please fill out the required fields below including your dependent’s email so that they can be invited to complete enrollment. You can add up to 3 dependent.`,
      dependent: "Take control of your heart health with KardiaComplete.",
    },
    profileTitle: {
      "*": "Create Your Account",
    },
    subProfileTitle: {
      "*": "Take control of your heart health with KardiaComplete.",
    },
    shippingTitle: {
      "*": "Where should we send your devices?",
    },
    subShippingTitle: {
      "*": "Every KardiaComplete member receives a Heart Health Kit, which includes a KardiaMobile 6L EKG device and an Omron Evolv blood pressure cuff.",
    },
    loginTitle: {
      "*": "Log In",
    },
    loginSubTitle: {
      "*": "Already have an account? Enter your log-in information below to complete registration.",
    },
    stepper: {
      provider: "Add Dependent",
      employee: "Enrollment Information",
      dependent: "Enrollment Information",
    },
    enrollButton: {
      "*": "Create Account",
    },
    dependentButton: {
      "*": "+ add another dependent",
    },
    form: {
      title: {
        employee: "enrollment information",
        provider: "enrollment information",
        dependent: "enrollment information",
      },
      accountFormTitle: {
        employee: "Profile Information",
        provider: "Profile Information",
        dependent: "Profile Information",
      },
      shippingFormTitle: {
        employee: "Shipping Address",
        provider: "Shipping Address",
        dependent: "Shipping Address",
      },
      subtitle: {
        provider: "",
        employee: "",
        dependent: "",
      },
      allFieldsRequired: {
        "*": "* Required",
      },
      hp: {
        employee: "I am an HP Employee",
        dependent: "I am a dependent of an HP Employee",
        retiree: "I am an HP retiree",
      },
      firstName: {
        employee: "first name",
        provider: "Employee First Name",
        dependent: "first name",
      },
      lastName: {
        employee: "last name",
        provider: "Employee Last Name",
        dependent: "last name",
      },
      dateOfBirth: {
        title: "date of birth",
        subtitle:
          "Your date of birth is collected to validate your eligibility into the KardiaComplete program.",
      },
      password: {
        "*": "Password",
      },
      confirmPassword: {
        "*": "Confirm Password",
      },
      email: {
        employee: "email",
        provider: "Employee Email",
        dependent: "email",
        subText:
          "If you have a Kardia account, use a different email address to register for KardiaComplete.",
      },
      dependentEmail: {
        "*": "dependent ${tokens[0]} email",
      },
      removeDependent: {
        "*": "remove dependent",
      },
      phone: {
        "*": "phone number",
      },
      state: {
        "*": "state",
      },
      height: {
        title: "Height",
        subtitle:
          "Your height is used to calculate BMI, a metric that is important to understanding health risks.",
      },
      inches: {
        "*": "Inches",
      },
      sex: {
        title: "Your Sex",
        subtitle:
          "Your sex is an important factor in understanding health risks and has measurable impact on your EKG reading determinations.",
      },
      race: {
        "*": "Your Race",
      },
      consent: {
        "*": "I agree to the Terms of Service and Privacy Policy",
      },
      marketing: {
        "*": "I consent to receive marketing communications from AliveCor about products and services. I can opt out at anytime, as described in the Privacy Policy.",
      },
      atrial: {
        "*": "I have Atrial Fibrillation",
      },
      arrhythmia: {
        "*": "I have another arrhythmia diagnosis",
      },
      hypertension: {
        "*": "I have Hypertension (high blood pressure)",
      },
      submitButton: {
        "*": "SUBMIT",
      },
      enrollButton: {
        "*": "Create Account",
      },
    },
    eligibility: {
      title: {
        employee:
          "You may not be eligible to enroll if you have experienced any of the following conditions",
        provider:
          "Your dependent may not be eligible to enroll if they have experienced any of the following conditions",
        dependent:
          "You may not be eligible to enroll if you have experienced any of the following conditions",
      },
      diseaseTitle: {
        "*": "Unstable Heart Disease",
      },
      diseaseDescription: {
        "*": "In hospital, or hospitalized for heart condition or stroke within the last six months.",
      },
      failureTitle: {
        "*": "Symptomatic Heart Failure",
      },
      failureDescription: {
        "*": "Congestive heart failure with frequent symptoms or exacerbations",
      },
      surgeryTitle: {
        "*": "Recent Heart Surgery",
      },
      surgeryDescription: {
        "*": "Heart surgery in the last six months",
      },
      restrictionTitle: {
        "*": "Anything Restricting Use of BP cuff or EKG",
      },
      restrictionDescription: {
        "*": "Issues with using Heart Health Kit",
      },
      dialysisTitle: {
        "*": "Dialysis",
      },
      dialysisDescription: {
        "*": "Currently undergoing dialysis",
      },
    },
  },
};

const interpolate = function (replacements) {
  return new Function("tokens", `return \`${this}\``)(replacements);
};

const getPath = (path) => {
  const segments = path.split(".");
  let newPath = textConstants;
  let pathString = "";
  segments.forEach((e) => {
    pathString = `${pathString}.${e}`;
    if (newPath[e]) newPath = newPath[e];
    else if (newPath["*"]) newPath = newPath["*"];
    else newPath = null;
  });
  return newPath;
};

const getTextConstants = (path, replacements, fallback) => {
  const text = getPath(path);
  if (!text && typeof fallback !== "string")
    throw new Error(`Path not found: ${path}`);
  return interpolate.call(text || fallback, replacements);
};

export default getTextConstants;
